import { createContext, useContext } from 'react';

type IntercomSpace =
  | 'home'
  | 'messages'
  | 'help'
  | 'news'
  | 'tasks'
  | 'tickets';

export type ShowIntercomSpace = (space: IntercomSpace) => void;

export type OpenNewIntercomMessage = (content?: string) => void;

export type ShowIntercomArticle = (articleId: string) => void;

export type IntercomContextType = {
  showIntercom: () => void;
  hideIntercom: () => void;
  resetIntercom: () => void;
  openNewIntercomMessage: OpenNewIntercomMessage;
  showIntercomSpace: ShowIntercomSpace;
  showIntercomArticle: ShowIntercomArticle;
};

export const IntercomContext = createContext<IntercomContextType>(
  {} as IntercomContextType
);

export function useIntercom(): IntercomContextType {
  return useContext(IntercomContext);
}
