import { ApiValidationError } from '@app/api/errors';
import useAuth from '@app/auth/use-auth';
import { useHubspot } from '@app/services/hubspot/useHubspot';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAccountCreate } from '@shared/api';
import TextLink from '@shared/components/content/text-link';
import CheckboxFormField from '@shared/components/form/checkbox-form-field';
import InputFormField from '@shared/components/form/input-form-field';
import Logo from '@shared/components/logo/logo';
import { Button } from '@shared/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@shared/components/ui/card';
import { Form } from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import sentry from '@shared/services/sentry';
import { ClassValue } from 'clsx';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { userSignupSchema } from './schema';

const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL;

interface SignupFormProps {
  className?: ClassValue;
}

export default function SignupForm({ className }: SignupFormProps) {
  const navigate = useNavigate();
  const { setToken } = useAuth();
  const hubspot = useHubspot();
  const { mutateAsync: createAccount, isLoading: creatingAccount } =
    useAccountCreate();

  const form = useForm<z.infer<typeof userSignupSchema>>({
    resolver: zodResolver(userSignupSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      organizationName: '',
      email: '',
      password: '',
      consentsToMarketing: false,
      phoneNumber: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof userSignupSchema>) => {
    try {
      const res = await createAccount({
        data: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          organizationName: data.organizationName,
          password: data.password,
          consentsToMarketing: data.consentsToMarketing,
          phoneNumber: data.phoneNumber,
        },
      });

      hubspot.identify(data.email);
      setToken(res.access);
      navigate('/sign-up/about-company');
    } catch (error: any) {
      if (error instanceof ApiValidationError) {
        const errors = error.data as ApiValidationError['data'];

        Object.keys(errors).forEach((err: any) => {
          form.setError(err, {
            type: 'server',
            message: errors[err],
          });
        });
      } else {
        sentry.log(error);
      }
    }
  };

  return (
    <Form {...form}>
      <Card
        className={cn(
          'min-w-[300px] max-w-md sm:px-6 py-6 p-4 shadow-md w-full mx-auto',
          className
        )}
      >
        <CardHeader className="flex items-center">
          <Logo className="size-14" />
          <CardTitle>Create your account</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <fieldset disabled={creatingAccount}>
              <div className="grid gap-4">
                <div className="flex items-start gap-4">
                  <InputFormField
                    name="firstName"
                    label="First name"
                    includeErrorMessage
                  />
                  <InputFormField
                    name="lastName"
                    label="Last name"
                    includeErrorMessage
                  />
                </div>
                <InputFormField
                  name="organizationName"
                  label="Company name"
                  placeholder="Your company"
                  includeErrorMessage
                />
                <InputFormField
                  name="email"
                  label="Work email"
                  placeholder="food@mycompany.com"
                  includeErrorMessage
                />
                <InputFormField
                  name="phoneNumber"
                  label="Phone number"
                  type="number"
                  includeErrorMessage
                />
                <InputFormField
                  name="password"
                  type="password"
                  label="Password"
                  includeErrorMessage
                />
                <CheckboxFormField
                  name="termsAndConditions"
                  label={
                    <p>
                      I agree to the{' '}
                      <TextLink
                        to={`${WEBSITE_URL}/subscription-terms-and-conditions/`}
                        internal={false}
                      >
                        My Emissions terms of use
                      </TextLink>
                    </p>
                  }
                  includeErrorMessage
                />
                <CheckboxFormField
                  name="consentsToMarketing"
                  label="I'm happy to receive emails from My Emissions"
                  includeErrorMessage
                />
                <Button
                  type="submit"
                  size="lg"
                  className="mt-4"
                  loading={creatingAccount}
                  disabled={creatingAccount}
                >
                  Create account
                </Button>
              </div>
            </fieldset>
          </form>

          <p className="mt-8 text-center text-sm text-muted-foreground ">
            Already have an account? <TextLink to={`/login`}>Login</TextLink>
          </p>
        </CardContent>
      </Card>
    </Form>
  );
}
