import { SelectProps } from '@radix-ui/react-select';
import { capitalizeFirstLetter, cn } from '@shared/lib/utils';
import { FormLabel } from './ui/form';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';

interface SimpleSelectProps extends SelectProps {
  defaultValue?: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  options: string[];
  className?: string;
  label?: string;
}
export default function SimpleSelect({
  options,
  placeholder,
  className,
  label,
  ...props
}: SimpleSelectProps) {
  return (
    <div className={cn('', className)}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select {...props}>
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {options.map((item) => (
              <SelectItem value={item} key={item}>
                {capitalizeFirstLetter(item)}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
