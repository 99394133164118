import { ApiValidationError } from '@app/api/errors';
import { useGetUnitList, useUpdateProduct } from '@shared/api';
import { ProductWithRelations } from '@shared/api/types';
import Alert from '@shared/components/alert';
import Heading from '@shared/components/content/heading';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { useDataTable } from '@shared/components/data-table/hooks/use-data-table';
import InputFormField from '@shared/components/form/input-form-field';
import { RepeatableFormRows } from '@shared/components/form/repeatable-form-rows';
import RecipeIngredientRow from '@shared/components/product-form/components/recipe-ingredient-row';
import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';
import { Button } from '@shared/components/ui/button';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface RecipeFormSectionProps {
  product: ProductWithRelations;
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'uuid',
  },
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <HeaderCell column={column} name="Ingredient" className="pl-0" />
    ),
    cell: ({ getValue }) => <p>{getValue<string>()}</p>,
    enableSorting: false,
  },
  {
    accessorKey: 'quantity',
    header: ({ column }) => (
      <HeaderCell column={column} name="Quantity" className="text-right" />
    ),
    cell: ({ getValue }) => {
      const quantity = getValue<number>();
      return quantity ? <NumberCell number={getValue<number>()} /> : null;
    },
    enableSorting: false,
  },
  {
    accessorKey: 'unit',
    header: ({ column }) => <HeaderCell column={column} name="Unit" />,
    cell: ({ getValue }) => <p>{getValue<string>()}</p>,
    enableSorting: false,
  },
  {
    accessorKey: 'emissionsCategory',
    header: ({ column }) => (
      <HeaderCell column={column} name="Rating" className="text-right" />
    ),
    cell: ({ getValue }) => {
      const rating = getValue<RatingCloudProps['rating']>();
      return (
        (rating && (
          <RatingCloud
            rating={rating as RatingCloudProps['rating']}
            className="ml-auto w-12"
          />
        )) ??
        null
      );
    },
    enableSorting: false,
  },
];

export default function RecipeFormSection({ product }: RecipeFormSectionProps) {
  const { toast } = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const { mutateAsync: updateProduct, isLoading: updatingProduct } =
    useUpdateProduct();
  const { trigger, getValues, resetField } = useFormContext();
  const { data: units } = useGetUnitList();

  const ingredientsData = useMemo(() => {
    return product.ingredients?.map((ingredient) => {
      const unit = units?.find((u) => u.uuid === ingredient.unitUuid);
      return {
        ...ingredient,
        unit: unit?.shortDesc,
      };
    });
  }, [units, product]);

  const handleUpdateRecipe = async () => {
    const validFields = await trigger(['servings', 'netWeight', 'ingredients']);
    if (!validFields) return;

    const [servings, netWeight, ingredients] = getValues([
      'servings',
      'netWeight',
      'ingredients',
    ]);
    try {
      const updatedProduct = await updateProduct({
        productUuid: product.uuid,
        data: {
          servings,
          netWeight: netWeight || null,
          ingredients,
        },
      });

      resetField('ingredients', {
        defaultValue: updatedProduct.ingredients,
      });

      setIsEditing(false);
    } catch (error) {
      const description =
        error instanceof ApiValidationError
          ? error.message
          : 'Please try again or contact us if the error persists';
      toast({
        title: 'An error occurred while updating your product',
        description,
        variant: 'destructive',
      });
      sentry.log(error);
    }
  };

  const { table } = useDataTable({
    columns,
    data: ingredientsData!,
    columnVisibilityState: {
      uuid: false,
    },
    pageSize: 200,
  });

  return (
    <div className="w-full max-w-form">
      <div className="mb-2">
        <div className="flex items-center justify-between">
          <Heading variant="section">Recipe</Heading>
          {isEditing ? null : (
            <Button
              variant="link"
              onClick={() => {
                setIsEditing(true);
              }}
              className="h-6"
            >
              Edit recipe
            </Button>
          )}
        </div>
        {!isEditing && (
          <span className="text-sm">
            {product?.servings} serving{product?.servings !== 1 && 's'}
            {product.netWeight && `, food net weight ${product.netWeight} kg`}
          </span>
        )}
      </div>
      {!isEditing ? (
        <div>
          <DataTable
            table={table}
            className="bg-gray-25"
            cellClassName="py-2 pl-0"
            headerConfig={{
              cell: 'pl-0',
            }}
          />
        </div>
      ) : (
        <fieldset disabled={updatingProduct} className="space-y-4">
          <div className="grid grid-cols-12 items-end gap-2">
            <InputFormField
              name="servings"
              type="number"
              label="Servings"
              className="col-span-3"
            />
            <InputFormField
              name="netWeight"
              type="number"
              className="col-span-4"
              inputClassName="text-right"
              label="Net food weight"
              adornmentRight="kg"
              step="0.001" // 1g
              optional
            />
          </div>
          {product.ingredients?.some(
            (ingredient) => !ingredient.entityType
          ) && (
            <Alert title="Recipe contains legacy ingredients" variant="warning">
              To update this recipe you will need to fix any rows that are
              missing an ingredient by selecting a new ingredient or product
              from the dropdown.
            </Alert>
          )}
          <RepeatableFormRows
            name="ingredients"
            component={RecipeIngredientRow}
            includeErrorMessage
            newRowObject={{
              entityUuid: '',
              quantity: 1,
              unitUuid: '',
              entityType: 'base_food',
            }}
          />
          <div className="flex justify-end space-x-2">
            <Button
              variant="secondary"
              type="button"
              onClick={async () => {
                setIsEditing(false);
                resetField('ingredients');
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleUpdateRecipe}
              loading={updatingProduct}
            >
              Update recipe
            </Button>
          </div>
        </fieldset>
      )}
    </div>
  );
}
