import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  getCompanyReportsActivitiesHeatListQueryKey,
  getCompanyReportsRetrieveQueryKey,
  useCompanyReportsActivitiesDestroy,
  useCompanyReportsActivitiesHeatCreate,
  useCompanyReportsActivitiesHeatList,
  useCompanyReportsActivitiesHeatRetrieve,
  useCompanyReportsActivitiesHeatUpdate,
} from '@shared/api/lib/company-reports/company-reports';
import {
  CompanyReportsActivitiesHeatListParams,
  HeatActivitiesList,
  HeatActivityRetrieve,
  PaginatedHeatActivitiesListList,
} from '@shared/api/types';
import { useQueryClient } from '@tanstack/react-query';

export const useGetHeatActivityList = (
  companyReportUuid: string,
  params?: CompanyReportsActivitiesHeatListParams
) => {
  return useCompanyReportsActivitiesHeatList<PaginatedHeatActivitiesListList>(
    companyReportUuid,
    params,
    {
      request: useAxiosConfig(),
      query: {
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    }
  );
};

export const useCreateHeatActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesHeatCreate<HeatActivitiesList>({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(
          getCompanyReportsActivitiesHeatListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.invalidateQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};

export const useGetHeatActivity = ({
  heatingActivityUuid,
  reportUuid,
  params,
}: {
  heatingActivityUuid?: string;
  reportUuid: string;
  params?: CompanyReportsActivitiesHeatListParams;
}) => {
  return useCompanyReportsActivitiesHeatRetrieve<HeatActivityRetrieve>(
    reportUuid,
    heatingActivityUuid!,
    params,
    {
      request: useAxiosConfig(),
      query: {
        enabled: !!heatingActivityUuid,
      },
    }
  );
};

export const useUpdateHeatActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesHeatUpdate<HeatActivitiesList>({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(
          getCompanyReportsActivitiesHeatListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.invalidateQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};

export const useDeleteHeatActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(
          getCompanyReportsActivitiesHeatListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.invalidateQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};
