import { generatePath } from 'react-router-dom';
import { ccfRoutes } from '../ccf-routes';

export function companyReportTabs(report_uuid: string) {
  return [
    {
      title: 'Summary',
      href: generatePath(ccfRoutes.COMPANY_REPORT, { report_uuid }),
    },
    {
      title: 'Scope 1',
      disabled: true,
      href: generatePath(ccfRoutes.STATIONARY_COMBUSTION, { report_uuid }),
      subTabs: [
        {
          title: 'Stationary combustion',
          href: generatePath(ccfRoutes.STATIONARY_COMBUSTION, { report_uuid }),
        },
        {
          title: 'Mobile combustion',
          href: generatePath(ccfRoutes.MOBILE_COMBUSTION, { report_uuid }),
        },
        {
          title: 'Other gas emissions',
          href: generatePath(ccfRoutes.OTHER_GAS, {
            report_uuid,
          }),
        },
      ],
    },
    {
      title: 'Scope 2',
      href: generatePath(ccfRoutes.ELECTRICITY, {
        report_uuid,
      }),
      subTabs: [
        {
          title: 'Electricity',
          href: generatePath(ccfRoutes.ELECTRICITY, { report_uuid }),
        },
        {
          title: 'Heating and steam',
          href: generatePath(ccfRoutes.HEATING, { report_uuid }),
        },
      ],
    },
    {
      title: 'Scope 3',
      href: generatePath(ccfRoutes.FOOD, { report_uuid }),
      subTabs: [
        {
          title: 'Food and Drink',
          href: generatePath(ccfRoutes.FOOD, {
            report_uuid,
          }),
        },
      ],
    },
  ];
}
