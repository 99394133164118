import { useGetCompanyReportsList } from '@shared/api';
import { DataTable } from '@shared/components/data-table/data-table';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { DataTableProps } from '@shared/components/data-table/types';
import Loader from '@shared/components/loader';
import { cn } from '@shared/lib/utils';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { ccfRoutes } from '../../ccf-routes';
import { reportTableColumns } from './ccf-list-table-columns';
import CCFReportCard from './ccf-report-card';
import { schema } from './scope-3/ccf-food-table';

interface CCFReportTableProps extends Partial<DataTableProps> {
  type?: 'table' | 'card';
}

const CCFReportsTableSchema = schema.extend({});

export default function CCFReportTable({
  enableRowClick = true,
  type = 'table',
}: CCFReportTableProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const search = CCFReportsTableSchema.parse(Object.fromEntries(searchParams));

  const { data, status } = useGetCompanyReportsList({
    page: search.page,
    page_size: search.page_size,
  });

  const { results: companyReports, count } = data || {};
  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const { table } = useServerDataTable({
    data: companyReports || [],
    columns: reportTableColumns,
    pageCount,
    defaultPerPage: 10,
    columnVisibilityState: {
      uuid: false,
    },
    rowId: 'uuid',
  });

  if (status === 'loading') return <Loader />;
  if (status === 'error') throw Error();

  return (
    <DataTable
      table={table}
      showHeader={type === 'card' ? false : true}
      onRowClick={(row) => {
        if (!enableRowClick) return;
        const uuid = row?.original.uuid;
        navigate(generatePath(ccfRoutes.COMPANY_REPORT, { report_uuid: uuid }));
      }}
      rowClassName={cn('', { 'bg-gray-25 border-none': type === 'card' })}
      enablePagination
      customRowItem={
        type === 'card'
          ? (row) => (
              <CCFReportCard
                report={row.original}
                className="mb-3 cursor-pointer"
                onClick={() => {
                  if (!enableRowClick) return;
                  const uuid = row?.original.uuid;
                  navigate(
                    generatePath(ccfRoutes.COMPANY_REPORT, {
                      report_uuid: uuid,
                    })
                  );
                }}
              />
            )
          : undefined
      }
    />
  );
}
