import { SimpleTooltip } from '@shared/components/simple-tooltip';
import { cn } from '@shared/lib/utils';
import { Link, useLocation } from 'react-router-dom';
import {
  ACTIVE_LINK_STYLE,
  BASE_LINK_STYLE,
  HOVER_LINK_STYLE,
} from '../styles';
import { NavItem } from '../types';

interface SidebarLinkProps {
  item: NavItem;
  className?: string;
  isCollapsed?: boolean;
}

function SidebarLink({
  item,
  isCollapsed = false,
  className,
}: SidebarLinkProps) {
  if (isCollapsed) {
    return <CollapsedSidebarLink item={item} className={className} />;
  }
  return <BaseSidebarLink item={item} className={className} />;
}

export default SidebarLink;

interface BaseSidebarLinkProps {
  item: NavItem;
  className?: string;
  showTitle?: boolean;
  showBadge?: boolean;
}

function BaseSidebarLink({
  item,
  className,
  showTitle = true,
  showBadge = true,
}: BaseSidebarLinkProps) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(item.to);

  return (
    <Link
      to={item.disabled ? '#' : item.to}
      target={item.newTab ? '_blank' : '_self'}
      className={cn(
        className,
        BASE_LINK_STYLE,
        !isActive && HOVER_LINK_STYLE,
        isActive && ACTIVE_LINK_STYLE,
        {
          'cursor-not-allowed hover:bg-transparent': item.disabled,
        }
      )}
    >
      {item.icon}
      {showTitle && <div className="flex-1">{item.title}</div>}
      {showBadge && item.badge}
    </Link>
  );
}

function CollapsedSidebarLink({ item, className }: SidebarLinkProps) {
  return (
    <SimpleTooltip
      trigger={
        <div className="flex items-center justify-center">
          <BaseSidebarLink
            item={item}
            className={className}
            showTitle={false}
            showBadge={false}
          />
        </div>
      }
      content={<p className="font-semibold">{item.title}</p>}
      side="right"
      className="max-w-[200px] px-4 py-2"
      delayDuration={200}
    />
  );
}
