import { TimePeriod, TimePeriodTypeEnum } from '@shared/api/types';
import { useMemo } from 'react';
import { SelectOption } from 'react-spreadsheet-import/types/types';

interface renderRowsProps {
  timePeriod: string;
  unit: string;
  timePeriods: TimePeriod[];
}

export const emptyReadingRow = {
  uuid: '',
  quantity: 0,
  unit: '',
  timePeriod: '',
};

export const renderRows = ({
  timePeriod,
  unit,
  timePeriods,
}: renderRowsProps) => {
  let number = 0;
  switch (timePeriod) {
    case TimePeriodTypeEnum.year:
      number = 1;
      break;
    case TimePeriodTypeEnum.quarter:
      number = 4;
      break;
    case TimePeriodTypeEnum.month:
      number = 12;
      break;
  }

  const rows = [];
  for (let i = 0; i < number; i++) {
    rows.push({
      uuid: '1',
      quantity: null,
      unit: unit,
      timePeriod: timePeriods[i].uuid,
    });
  }
  return rows;
};

export const useGetPeriodOptions = (periods: TimePeriod[]) => {
  return useMemo(
    () =>
      periods?.map(({ uuid, name }) => ({
        value: uuid,
        label: name,
      })) as SelectOption[] | [],
    [periods]
  );
};

export function useUnitOptions(units: any[]): SelectOption[] {
  return useMemo(
    () =>
      units?.map((unit) => ({
        value: unit.uuid,
        label: unit.name,
        shortLabel: unit.symbol,
      })) || [],
    [units]
  ) as SelectOption[];
}
