export const ccfRoutes = {
  COMPANY_REPORTS: '/company-reports',
  COMPANY_REPORT_ACCESS: '/company-reports/access',
  COMPANY_REPORT: '/company-reports/:report_uuid',
  IMPORT_FOOD: '/company-reports/:report_uuid/food-and-drink/import',
  IMPORTING_FOOD:
    '/company-reports/:report_uuid/food-and-drink/import/importing',
  IMPORT_DOWNLOAD:
    '/company-reports/:report_uuid/food-and-drink/import/download',
  FOOD: '/company-reports/:report_uuid/food',
  REPORT_SETUP: '/company-reports/setup',
  REPORT_SETUP_WELCOME: '/company-reports/welcome',
  STATIONARY_COMBUSTION: '/company-reports/:report_uuid/stationary-combustion',
  MOBILE_COMBUSTION: '/company-reports/:report_uuid/mobile-combustion',
  OTHER_GAS: '/company-reports/:report_uuid/other-gas',
  ELECTRICITY: '/company-reports/:report_uuid/electricity',
  HEATING: '/company-reports/:report_uuid/heating-and-steam',
  SITES: '/sites',
  ELECTRICITY_SUPPLIES: '/sites/electricity-supplies',
};
