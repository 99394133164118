import Page from '@app/components/layout/page/page';
import ElectricitySuppliesTable from '../components/electricity-supplies-table';

export default function ElectricitySuppliesPage() {
  return (
    <Page name="Electricity supplies">
      <ElectricitySuppliesTable />
    </Page>
  );
}
