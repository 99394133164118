import { useGetElectricitySupplies } from '@shared/api';
import { Site } from '@shared/api/types';
import { CountryCell } from '@shared/components/data-table/cells/country-cell';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { TextCell } from '@shared/components/data-table/cells/text-cell';
import { ColumnDef } from '@tanstack/react-table';
import siteTypeOptions from './site-type-options';

const ElectricitySupplyCell = ({ supplyUuid }: { supplyUuid: string }) => {
  const { data } = useGetElectricitySupplies({
    page: 1,
    page_size: 1000,
    has_certificate: true,
  });

  if (!supplyUuid)
    return (
      <TextCell
        text="Standard location-based"
        className="text-muted-foreground"
      />
    );

  const supply =
    data && data?.results?.find((supply) => supply.uuid === supplyUuid);

  return <TextCell text={supply?.name || ''} />;
};

export const siteTableColumns: ColumnDef<Site>[] = [
  {
    accessorKey: 'uuid',
    enableHiding: true,
  },
  {
    accessorKey: 'name',
    enableSorting: false,
    header: ({ column }) => <HeaderCell column={column} name="Site" />,
    cell: ({ getValue }) => <TextCell text={getValue<string>()} />,
  },
  {
    accessorKey: 'country',
    enableSorting: false,
    header: ({ column }) => <HeaderCell column={column} name="Country" />,
    cell: ({ getValue }) => <CountryCell uuid={getValue<string>()} />,
  },
  {
    accessorKey: 'type',
    enableSorting: false,
    header: ({ column }) => <HeaderCell column={column} name="Category" />,
    cell: ({ getValue }) => {
      const siteType = siteTypeOptions.find(
        (option) => option.value === getValue<string>()
      );
      return <TextCell text={siteType?.label || ''} />;
    },
  },
  {
    accessorKey: 'electricitySupply',
    enableSorting: false,
    header: ({ column }) => (
      <HeaderCell column={column} name="Electricity supply" />
    ),
    cell: ({ getValue }) => (
      <ElectricitySupplyCell supplyUuid={getValue<string>()} />
    ),
  },
];
