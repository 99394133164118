import Page from '@app/components/layout/page/page';
import { CompanyReportSummary } from '@shared/api/types';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { Accordion } from '@shared/components/ui/accordion';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { ccfRoutes } from '../../ccf-routes';
import { useCCF } from '../ccf-layout';
import HubAccordionItem from '../components/hub-accordion-item';
import HubCard from '../components/hub-card';
import { ScopeCategory, useScopeCategories } from '../scopes';

export default function ReportHubPage() {
  const { reportUuid, summary } = useCCF();
  const baseCategories = useScopeCategories();

  const { scope1, scope2, scope3 } = useMemo(
    () =>
      baseCategories?.reduce(
        (acc, item) => {
          const matchingLiveCategory = summary?.find(
            (category: CompanyReportSummary) => category.slug === item.slug
          );

          if (matchingLiveCategory) {
            item = {
              ...item,
              totalEmissions: matchingLiveCategory.totalEmissions!,
              statusDescription: matchingLiveCategory.statusDescription,
              index: matchingLiveCategory.index,
            };
          }
          acc['scope' + item.scope].push(item);
          return acc;
        },
        {
          scope1: [],
          scope2: [],
          scope3: [],
        } as Record<string, ScopeCategory[]>
      ),
    [baseCategories, summary]
  );

  return (
    <Page name="Company carbon footprint hub">
      <div className="flex">
        <div className="w-full min-w-fit max-w-form grow">
          <Accordion
            type="multiple"
            defaultValue={['scope2', 'scope3']}
            className="w-full space-y-2"
          >
            <HubAccordionItem title="Scope 1 - Direct emissions" value="scope1">
              <Text variant="subtle" className="mb-2">
                The emissions from any process or activity within your company
                that directly produces greenhouse gases.
              </Text>
              {scope1.map((item) => {
                return (
                  <HubCard
                    key={item.slug}
                    slug={item.slug}
                    name={item.name}
                    totalEmissions={item.totalEmissions!}
                    description={item.description}
                    statusDescription={item.statusDescription!}
                    cardStatus={item.cardStatus}
                    href={item.href}
                    icon={item.icon}
                  />
                );
              })}
            </HubAccordionItem>
            <HubAccordionItem
              title="Scope 2 - Indirect emissions"
              value="scope2"
            >
              <Text variant="subtle" className="mb-2">
                The emissions from the energy your business purchases, generated
                by energy providers on your behalf.
              </Text>
              {scope2.map((item) => {
                return (
                  <HubCard
                    key={item.slug}
                    slug={item.slug}
                    name={item.name}
                    totalEmissions={item.totalEmissions!}
                    description={item.description}
                    statusDescription={item.statusDescription!}
                    cardStatus={item.cardStatus}
                    href={item.href}
                    icon={item.icon}
                  />
                );
              })}
            </HubAccordionItem>

            <HubAccordionItem
              title="Scope 3 - Other indirect emissions"
              value="scope3"
            >
              <Text variant="subtle" className="mb-2">
                The emissions that occur across your supply chain, from the
                production of the ingredients you purchase to waste disposal.
              </Text>
              {scope3.map((item) => {
                return (
                  <HubCard
                    key={item.slug}
                    slug={item.slug}
                    name={item.name}
                    totalEmissions={item.totalEmissions!}
                    description={item.statusDescription!}
                    statusDescription={item.statusDescription!}
                    href={generatePath(ccfRoutes.FOOD, {
                      report_uuid: reportUuid,
                    })}
                    icon={
                      <Icons.carrot className=" text-orange-400" size={20} />
                    }
                  />
                );
              })}
            </HubAccordionItem>
          </Accordion>
        </div>
      </div>
    </Page>
  );
}
