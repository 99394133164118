import useAuth from '@app/auth/use-auth';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import {
  IntercomContext,
  OpenNewIntercomMessage,
  ShowIntercomArticle,
  ShowIntercomSpace,
} from './IntercomContext';

import Intercom, {
  boot,
  hide,
  show,
  showArticle,
  showNewMessage,
  showSpace,
  shutdown,
} from '@intercom/messenger-js-sdk';

const INTERCOM_APP_ID = 'l22rikuz';

Intercom({
  app_id: INTERCOM_APP_ID,
});

export const IntercomProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const [loaded, setLoaded] = useState<boolean>();

  useEffect(() => {
    if (!loaded && user && !user.isStaff && user.intercomHash) {
      const createdDate = new Date(user.createdAt);
      const createdTime = Math.floor(createdDate.getTime() / 1000);
      boot({
        app_id: INTERCOM_APP_ID,
        user_id: user.uuid,
        user_hash: user.intercomHash,
        name: user.userName,
        email: user.userEmail,
        company: {
          id: user.organizationUuid,
          name: user.organizationName,
          plan: user.organizationPlan,
          'Product category': user.productCategory,
        },
        created_at: createdTime,
      });
      setLoaded(true);
    }
  }, [loaded, user]);

  const showIntercom = useCallback(() => {
    if (loaded) {
      show();
    }
  }, [loaded]);

  const hideIntercom = useCallback(() => {
    if (loaded) {
      hide();
    }
  }, [loaded]);

  const resetIntercom = useCallback(() => {
    if (loaded) {
      shutdown();
      setLoaded(false);
    }
  }, [loaded]);

  const openNewIntercomMessage: OpenNewIntercomMessage = useCallback(
    (content) => {
      if (loaded) {
        showNewMessage(content || '');
      }
    },
    [loaded]
  );

  const showIntercomSpace: ShowIntercomSpace = useCallback(
    (space) => {
      if (loaded) {
        showSpace(space);
      }
    },
    [loaded]
  );

  const showIntercomArticle: ShowIntercomArticle = useCallback(
    (articleId) => {
      if (loaded) {
        showArticle(articleId);
      }
    },
    [loaded]
  );

  const value = {
    showIntercom,
    hideIntercom,
    resetIntercom,
    openNewIntercomMessage,
    showIntercomSpace,
    showIntercomArticle,
  };

  return (
    <IntercomContext.Provider value={value}>
      {children}
    </IntercomContext.Provider>
  );
};
