import { useGetCountries } from '@shared/api';
import { cn } from '@shared/lib/utils';
import { ClassValue } from 'clsx';

interface CountryCellProps {
  uuid: string;
  className?: ClassValue;
}

export function CountryCell({ uuid, className }: CountryCellProps) {
  const { data: countries } = useGetCountries();
  const country = countries?.find((country) => country.uuid === uuid);
  return <p className={cn('', className)}>{country?.name}</p>;
}
