import Page from '@app/components/layout/page/page';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { useMemo } from 'react';
import { useCCF } from '../../ccf-layout';
import HeatingEditForm from '../../components/scope-2/heating-edit-form';
import HeatingTable from '../../components/scope-2/heating-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function HeatingPage() {
  const { showIntercomArticle } = useIntercom();
  const { summary } = useCCF();
  const {
    showSimpleDialog: showHeatingDialog,
    SimpleDialogComponent: HeatingDialog,
    closeSimpleDialog: closeHeatingDialog,
  } = useSimpleDialog();

  const categoryStatus = useMemo(
    () => summary?.filter((item) => item.slug === 'heat')[0].status,
    [summary]
  );
  return (
    <Page name="Heating & Steam">
      {categoryStatus != 'unstarted' ? (
        <HeatingTable />
      ) : (
        <EmptyCategoryPage
          category="Heating"
          description="The heating and steam you purchase and consume on your sites."
          primaryActionProps={{
            onClick: showHeatingDialog,
          }}
          secondaryActionProps={{
            onClick: () => showIntercomArticle('10117592'),
          }}
          image="https://via.placeholder.com/150"
        />
      )}
      {HeatingDialog({
        header: 'Add heating and steam reading',
        content: <HeatingEditForm closeDialog={closeHeatingDialog} />,
      })}
    </Page>
  );
}
