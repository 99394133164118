import useAuth from '@app/auth/use-auth';
import { Icons } from '@shared/components/content/icons';
import { SIDEBAR_ICON_STYLE } from './styles';
import {
  NavCategory,
  NavItem,
  SidebarItem,
  SidebarItemPosition,
} from './types';

const PRODUCTS_ITEM: SidebarItem = {
  item: {
    title: 'Products',
    icon: <Icons.salad className={SIDEBAR_ICON_STYLE} />,
    items: [
      {
        title: 'Dashboard',
        icon: <Icons.dashboard className={SIDEBAR_ICON_STYLE} />,
        to: '/products/dashboard',
      },
      {
        title: 'All products',
        icon: <Icons.salad className={SIDEBAR_ICON_STYLE} />,
        to: '/products',
      },
      {
        title: 'Add product',
        icon: <Icons.plus className={SIDEBAR_ICON_STYLE} />,
        to: '/products/create',
      },
      {
        title: 'Collections',
        icon: <Icons.tags className={SIDEBAR_ICON_STYLE} />,
        to: '/collections',
      },
    ],
  } as NavCategory,
  position: SidebarItemPosition.Top,
};

const PRODUCTS_ACCESS_ITEM: SidebarItem = {
  item: {
    title: 'Products',
    icon: <Icons.salad className={SIDEBAR_ICON_STYLE} />,
    to: '/products/access',
    disabled: true,
    badge: <Icons.lock className={SIDEBAR_ICON_STYLE} />,
  } as NavItem,
  position: SidebarItemPosition.Top,
};

const COMPANY_REPORTS_ITEM: SidebarItem = {
  item: {
    title: 'Company',
    icon: <Icons.company className={SIDEBAR_ICON_STYLE} />,
    items: [
      {
        title: 'Reports',
        icon: <Icons.clipboardList className={SIDEBAR_ICON_STYLE} />,
        to: '/company-reports',
      },
      {
        title: 'Sites',
        icon: <Icons.factory className={SIDEBAR_ICON_STYLE} />,
        to: '/sites',
      },
    ],
  } as NavCategory,
  position: SidebarItemPosition.Top,
};

const COMPANY_REPORTS_ACCESS_ITEM: SidebarItem = {
  item: {
    title: 'Company',
    icon: <Icons.company className={SIDEBAR_ICON_STYLE} />,
    to: '/company-reports/access',
    disabled: true,
    badge: <Icons.lock className={SIDEBAR_ICON_STYLE} />,
  } as NavItem,
  position: SidebarItemPosition.Top,
};

const REPORTS_ITEM: SidebarItem = {
  item: {
    title: 'Reports',
    icon: <Icons.lineChart className={SIDEBAR_ICON_STYLE} />,
    to: '/reports',
  } as NavItem,
  position: SidebarItemPosition.Top,
};

const HELP_CENTER_ITEM: SidebarItem = {
  item: {
    title: 'Help Center',
    icon: <Icons.lifeBuoy className={SIDEBAR_ICON_STYLE} />,
    to: 'https://help.myemissions.co',
    newTab: true,
  } as NavItem,
  position: SidebarItemPosition.Bottom,
};

const SETTINGS_ITEM: SidebarItem = {
  item: {
    title: 'Account',
    icon: <Icons.circleUser className={SIDEBAR_ICON_STYLE} />,
    to: '/settings',
  } as NavItem,
  position: SidebarItemPosition.Bottom,
};

const INGREDIENTS_ITEM: NavItem = {
  title: 'Ingredients',
  icon: <Icons.sprout className={SIDEBAR_ICON_STYLE} />,
  to: '/ingredients',
};

const PACKAGING_ITEM: NavItem = {
  title: 'Packaging',
  icon: <Icons.package className={SIDEBAR_ICON_STYLE} />,
  to: '/packaging',
};

const PROCESSING_ITEM: NavItem = {
  title: 'Processing',
  icon: <Icons.factory className={SIDEBAR_ICON_STYLE} />,
  to: '/processing',
};

const TRANSPORT_ITEM: NavItem = {
  title: 'Transport',
  icon: <Icons.truck className={SIDEBAR_ICON_STYLE} />,
  to: '/transport',
};

const createInputsItem = (items: NavItem[]): SidebarItem => ({
  item: {
    title: 'Inputs',
    icon: <Icons.puzzle className={SIDEBAR_ICON_STYLE} />,
    items,
  } as NavCategory,
  position: SidebarItemPosition.Top,
});

export const useSidebarItems: () => SidebarItem[] = () => {
  const { user } = useAuth();

  const { features, productCategory } = user!;

  const inputItems = createInputsItem([
    INGREDIENTS_ITEM,
    ...(features.pcf && productCategory === 'product' ? [PROCESSING_ITEM] : []),
    ...(features.pcf ? [PACKAGING_ITEM] : []),
    ...(features.pcf && productCategory === 'product' ? [TRANSPORT_ITEM] : []),
  ]);

  const productItem = features.pcf ? PRODUCTS_ITEM : PRODUCTS_ACCESS_ITEM;
  const companyItem = features.ccf
    ? COMPANY_REPORTS_ITEM
    : COMPANY_REPORTS_ACCESS_ITEM;

  return [
    productItem,
    companyItem,
    inputItems,
    ...(features.reporting ? [REPORTS_ITEM] : []),
    HELP_CENTER_ITEM,
    SETTINGS_ITEM,
  ];
};
