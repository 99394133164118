import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@shared/components/ui/command';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import Fuse, { FuseOptionKey } from 'fuse.js';
import { Check } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Icons } from '../content/icons';
import { Button } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import InputLabel, { InputLabelProps } from './input-label';
import { FormProps } from './types';

export interface SelectSearchOption {
  value: string;
  label: string;
}

interface SelectSearchFormFieldProps extends FormProps, InputLabelProps {
  options: SelectSearchOption[];
  disabled?: boolean;
  placeholder?: string;
  pluralName?: string;
}

interface UseSearchProps<T> {
  items: T[];
  searchQuery: string;
  searchKeys: FuseOptionKey<T>[];
}

const useSearch = <T,>({
  items,
  searchQuery,
  searchKeys,
}: UseSearchProps<T>): T[] => {
  const fuse = useMemo(() => {
    return new Fuse(items, {
      keys: searchKeys,
      threshold: 0.1,
      shouldSort: false,
    });
  }, [items, searchKeys]);

  if (!searchQuery) {
    return items;
  }
  return fuse.search(searchQuery).map((result) => result.item);
};

export default function SelectSearchFormField({
  name,
  includeErrorMessage = false,
  className,
  label,
  options,
  disabled,
  placeholder,
  pluralName,
  tooltip,
  optional = false,
}: SelectSearchFormFieldProps) {
  const { control } = useFormContext();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredOptions = useSearch({
    items: options,
    searchQuery: searchQuery,
    searchKeys: ['label'],
  });

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn('grid items-end w-full', className)}>
          {label && (
            <InputLabel
              label={label}
              name={name}
              optional={optional}
              tooltip={tooltip}
            />
          )}
          <FormControl>
            <div className="max-w-full overflow-hidden">
              <Popover
                open={open}
                onOpenChange={(isOpen) => {
                  setOpen(isOpen);
                  setSearchQuery('');
                }}
                modal={true}
              >
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={cn(
                      'h-10 w-full justify-between font-normal shadow-none hover:bg-background truncate flex flex-row items-center',
                      {
                        'cursor-not-allowed opacity-50': disabled,
                        'border-error-500': fieldState.error,
                      }
                    )}
                    disabled={disabled}
                  >
                    <span className="flex-1 overflow-hidden text-ellipsis text-left">
                      {field.value
                        ? options.find((option) => option.value === field.value)
                            ?.label || placeholder
                        : placeholder || ''}
                    </span>
                    <Icons.chevronDown className="ml-2 size-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0" align="start">
                  <Command shouldFilter={false}>
                    <CommandInput
                      placeholder={`Search ...`}
                      onValueChange={(value) => {
                        setSearchQuery(value);
                      }}
                    />
                    <CommandEmpty className="pb-2 pt-4">
                      No {pluralName || 'items'} found
                    </CommandEmpty>
                    <CommandGroup>
                      <CommandList>
                        {filteredOptions.length > 0 &&
                          filteredOptions.map((option) => (
                            <CommandItem
                              key={option.value}
                              value={option.value}
                              onSelect={() => {
                                field.onChange(option.value);
                                setOpen(false);
                              }}
                            >
                              <Check
                                className={cn(
                                  'mr-2 h-4 w-4 flex-shrink-0',
                                  field.value === option.value
                                    ? 'opacity-100'
                                    : 'opacity-0'
                                )}
                              />
                              {option.label}
                            </CommandItem>
                          ))}
                      </CommandList>
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          </FormControl>
          {includeErrorMessage && <FormMessage />}
        </FormItem>
      )}
    />
  );
}
