import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useGetElectricitySupplies } from '@shared/api';
import { ElectricitySupply } from '@shared/api/types';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { DataTableFilterField } from '@shared/components/data-table/types';
import { Button } from '@shared/components/ui/button';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { electricitySuppliesTableColumns } from './electricity-supplies-table-columns';
import ElectricitySupplyForm from './electricity-supply-form';

export const schema = z.object({
  page: z.coerce.number().default(1),
  page_size: z.coerce.number().optional(),
  sort: z.string().optional(),
  name: z.string().optional(),
});

export default function ElectricitySuppliesTable() {
  const [selectedSupply, setSelectedSupply] = useState<ElectricitySupply>();
  const resetSelectedSupply = () => setSelectedSupply(undefined);
  const { showSimpleDialog, SimpleDialogComponent, closeSimpleDialog } =
    useSimpleDialog(resetSelectedSupply);
  const [searchParams] = useSearchParams();
  const search = schema.parse(Object.fromEntries(searchParams));

  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetElectricitySupplies({
      page: search.page,
      page_size: search.page_size,
      search: search.name || '',
    });

  if (isError) throw new Error();

  const { results: supplies = [], count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const filterFields: DataTableFilterField<ElectricitySupply>[] = [
    {
      label: 'Name',
      value: 'name',
      placeholder: 'Search supplies...',
    },
  ];

  const { table } = useServerDataTable({
    data: supplies,
    columns: electricitySuppliesTableColumns,
    pageCount,
    filterFields,
    defaultPerPage: 10,
    rowId: 'uuid',
    columnVisibilityState: {
      uuid: false,
      period: false,
    },
  });

  return (
    <>
      <DataTable
        table={table}
        enablePagination
        className="bg-gray-00"
        onRowClick={(row) => {
          setSelectedSupply(row?.original as ElectricitySupply);
          showSimpleDialog();
        }}
        isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
      >
        <DataTableToolbar table={table} filterFields={filterFields}>
          <Button size="sm" onClick={showSimpleDialog}>
            Add supply
          </Button>
        </DataTableToolbar>
      </DataTable>
      {SimpleDialogComponent({
        header: `${selectedSupply ? 'Edit' : 'Add'} electricity supply`,
        content: (
          <ElectricitySupplyForm
            supply={selectedSupply}
            closeDialog={() => {
              setSelectedSupply(undefined);
              closeSimpleDialog();
            }}
          />
        ),
      })}
    </>
  );
}
