import { useAxiosConfig } from '@shared/api/AxiosConfigProvider';
import {
  getCompanyReportsActivitiesElectricityListQueryKey,
  getCompanyReportsRetrieveQueryKey,
  useCompanyReportsActivitiesDestroy,
  useCompanyReportsActivitiesElectricityCreate,
  useCompanyReportsActivitiesElectricityList,
  useCompanyReportsActivitiesElectricityRetrieve,
  useCompanyReportsActivitiesElectricityUpdate,
} from '@shared/api/lib/company-reports/company-reports';
import {
  CompanyReportsActivitiesElectricityListParams,
  ElectricityActivitiesList,
  ElectricityActivityRetrieve,
  PaginatedElectricityActivitiesListList,
} from '@shared/api/types';
import { useQueryClient } from '@tanstack/react-query';

export const useGetElectricityActivityList = (
  companyReportUuid: string,
  params?: CompanyReportsActivitiesElectricityListParams
) => {
  return useCompanyReportsActivitiesElectricityList<PaginatedElectricityActivitiesListList>(
    companyReportUuid,
    params,
    {
      request: useAxiosConfig(),
      query: {
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 60 * 24,
      },
    }
  );
};

export const useCreateElectricityActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesElectricityCreate<ElectricityActivitiesList>(
    {
      request: useAxiosConfig(),
      mutation: {
        onSuccess: (data, variables) => {
          queryClient.removeQueries(
            getCompanyReportsActivitiesElectricityListQueryKey(
              variables.companyReportUuid
            )
          );
          queryClient.invalidateQueries(
            getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
          );
        },
      },
    }
  );
};

export const useGetElectricityActivity = ({
  electricityActivityUuid,
  reportUuid,
  params,
}: {
  electricityActivityUuid?: string;
  reportUuid: string;
  params?: CompanyReportsActivitiesElectricityListParams;
}) => {
  return useCompanyReportsActivitiesElectricityRetrieve<ElectricityActivityRetrieve>(
    reportUuid,
    electricityActivityUuid!,
    params,
    {
      request: useAxiosConfig(),
      query: {
        enabled: !!electricityActivityUuid,
      },
    }
  );
};

export const useUpdateElectricityActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesElectricityUpdate<ElectricityActivitiesList>(
    {
      request: useAxiosConfig(),
      mutation: {
        onSuccess: (data, variables) => {
          queryClient.removeQueries(
            getCompanyReportsActivitiesElectricityListQueryKey(
              variables.companyReportUuid
            )
          );
          queryClient.invalidateQueries(
            getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
          );
        },
      },
    }
  );
};

export const useDeleteElectricityActivity = () => {
  const queryClient = useQueryClient();
  return useCompanyReportsActivitiesDestroy({
    request: useAxiosConfig(),
    mutation: {
      onSuccess: (data, variables) => {
        queryClient.removeQueries(
          getCompanyReportsActivitiesElectricityListQueryKey(
            variables.companyReportUuid
          )
        );
        queryClient.invalidateQueries(
          getCompanyReportsRetrieveQueryKey(variables.companyReportUuid)
        );
      },
    },
  });
};
