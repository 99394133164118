import useAuth from '@app/auth/use-auth';
import { CompareDialog } from '@app/components/comparisons/components/comparison-dialog';
import PageHeaderToolbar from '@app/components/layout/page/components/page-header-toolbar';
import ProductPdfExport from '@app/components/ProductPdfExport';
import { SimpleDialog } from '@app/hooks/use-simple-dialog';
import { useDeleteProduct } from '@shared/api';
import { ProductResult } from '@shared/api/types';
import { Button } from '@shared/components/ui/button';
import { useToast } from '@shared/components/ui/use-toast';
import sentry from '@shared/services/sentry';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import generatePDF, { Options } from 'react-to-pdf';

interface ProductDetailPageToolbarProps {
  product: ProductResult;
}

export default function ProductDetailPageToolbar({
  product,
}: ProductDetailPageToolbarProps) {
  const { user } = useAuth();

  const { toast } = useToast();
  const navigate = useNavigate();

  const [exportingPdf, setExportingPdf] = useState(false);

  const { mutateAsync: deleteProduct, isLoading: isDeleting } =
    useDeleteProduct();

  const editPage = `/products/${product.uuid}/edit`;

  const handleOnDelete = async () => {
    try {
      await deleteProduct({ productUuid: product.uuid });
      toast({
        description: 'Product deleted',
      });
      navigate('/products');
    } catch (error) {
      sentry.log(error);
    }
  };

  const onPdfExportRequest = async () => {
    await setExportingPdf(true);
    setTimeout(async () => {
      await generatePDF(getTargetElement, options);
    }, 700);
    setTimeout(() => {
      setExportingPdf(false);
    }, 700);
  };

  const getTargetElement = () => document.getElementById('pdf-export');

  const options: Options = {
    overrides: {
      canvas: {
        backgroundColor: '#f8f8f8',
        ignoreElements: (element) => {
          return element.id === 'pdf-export-button';
        },
        width: 1252,
        height: 1750,
      },
    },
    filename: `Product Carbon Summary - ${product.name}`,
  };

  return (
    <PageHeaderToolbar>
      <CompareDialog
        product_a={product.uuid}
        disabled={product.status === 'in progress'}
      />
      <Button
        variant="ghost"
        className="justify-start"
        onClick={() => onPdfExportRequest()}
        disabled={
          user.organizationPlan === 'free' ||
          user.organizationPlan === null ||
          exportingPdf
        }
        loading={exportingPdf}
        id="pdf-export-button"
      >
        Download
      </Button>
      <Button
        variant="ghost"
        className="w-full justify-start"
        onClick={() => navigate(editPage)}
      >
        Edit
      </Button>
      <SimpleDialog
        trigger={
          <Button variant="ghost" className="justify-start text-destructive">
            Delete
          </Button>
        }
        header="Delete product"
        content={
          <p>
            Are you sure you want to delete this product?
            <p className="mt-2 font-bold">This action cannot be undone.</p>
          </p>
        }
        footer={
          <Button
            type="submit"
            onClick={async () => handleOnDelete()}
            loading={isDeleting}
            variant="destructive"
          >
            Delete
          </Button>
        }
      />
      {exportingPdf && product && (
        <p
          id="pdf-export"
          style={{
            marginTop: '1600px',
            overflow: 'scroll',
            width: 1252,
            minWidth: 1252,
          }}
        >
          <ProductPdfExport
            productResultsData={product}
            productName={product.name}
          />
        </p>
      )}
    </PageHeaderToolbar>
  );
}
