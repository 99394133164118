import IngredientEmissionsInsight from '@app/pages/ingredients/components/ingredient-emissions-insights';
import { useGetIngredients } from '@shared/api';
import {
  AppOrganizationFoodsListEmissionsCategoryItem,
  AppOrganizationFoodsListSortItem,
  EmissionsCategoryEnum,
  OrganizationFoodList,
} from '@shared/api/types';
import Unit from '@shared/components/content/unit';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useGetStaticFilterOptions } from '@shared/components/data-table/hooks/use-get-static-filter-options';
import { useServerDataTable } from '@shared/components/data-table/hooks/use-server-data-table';
import { DataTableFilterField } from '@shared/components/data-table/types';
import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';
import { ColumnDef } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const formatSources = (sources: OrganizationFoodList['sources']) => {
  const uniqueSources = new Set(sources.map((source) => source.name));
  const concatenatedString = Array.from(uniqueSources).join(', ');
  return concatenatedString;
};

function getColumns(): ColumnDef<OrganizationFoodList>[] {
  return [
    {
      accessorKey: 'baseFoodUuid',
    },
    {
      accessorKey: 'longDesc',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Ingredient"
          className="text-gray-00"
        />
      ),
      cell: ({ getValue, row }) => {
        const name = getValue<string>();
        const infoMarkdown = row.original.infoMarkdown;
        const emissionsCategory = row.original.emissionsCategory;
        return (
          <span className="flex items-center">
            <p className="max-w-[240px] truncate">{name}</p>
            {infoMarkdown && (
              <IngredientEmissionsInsight
                emissionsCategory={emissionsCategory}
                markdown={infoMarkdown}
                longDesc={name}
              />
            )}
          </span>
        );
      },
      enableHiding: false,
    },
    {
      accessorKey: 'sources',
      header: ({ column }) => (
        <HeaderCell column={column} name="Sources" textWhite />
      ),
      cell: ({ getValue }) => {
        const sources = getValue<OrganizationFoodList['sources']>();
        const concatenatedSources = formatSources(sources);
        return <p>{concatenatedSources}</p>;
      },
      enableSorting: false,
    },
    {
      accessorKey: 'totalEmissionsPerKg',
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Carbon Intensity"
          unit={<Unit variant="kgCO2e/kgfood" className="text-primary-50" />}
          textWhite
        />
      ),
      cell: ({ getValue }) => {
        const totalEmissions: number = getValue<number>();
        return (
          (totalEmissions && (
            <NumberCell className="w-[100px]" number={totalEmissions} rounded />
          )) ??
          null
        );
      },
    },
    {
      accessorKey: 'emissionsCategory',
      header: ({ column }) => (
        <HeaderCell column={column} name="Rating" textWhite />
      ),
      cell: ({ getValue }) => {
        const rating = getValue<RatingCloudProps['rating']>();
        return (
          <RatingCloud
            rating={rating as RatingCloudProps['rating']}
            className={rating ? '' : 'w-[46px]'}
          />
        );
      },
      enableSorting: true,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
  ] as ColumnDef<OrganizationFoodList>[];
}

const schema = z.object({
  page: z.coerce.number().default(1),
  page_size: z.coerce.number().optional(),
  sort: z.string().optional(),
  longDesc: z.string().optional(),
});

export default function IngredientsTable() {
  const [searchParams] = useSearchParams();
  const search = schema.parse(Object.fromEntries(searchParams));

  const emissionsCategory = searchParams
    .get('emissionsCategory')
    ?.split('.') as AppOrganizationFoodsListEmissionsCategoryItem[];

  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetIngredients({
      page: search.page,
      page_size: search.page_size,
      emissions_category: emissionsCategory,
      search: search.longDesc || '',
      sort: [search.sort] as AppOrganizationFoodsListSortItem[],
    });

  if (isError) throw new Error();

  const { results: ingredients, count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const filterFields: DataTableFilterField<OrganizationFoodList>[] = [
    {
      label: 'Ingredient',
      value: 'longDesc',
      placeholder: 'Search ingredient...',
    },
    {
      label: 'Rating',
      value: 'emissionsCategory',
      options: useGetStaticFilterOptions(EmissionsCategoryEnum),
    },
  ];

  const { table } = useServerDataTable({
    data: ingredients || [],
    columns: getColumns(),
    pageCount,
    filterFields,
    defaultPerPage: 10,
    columnVisibilityState: {
      baseFoodUuid: false,
    },
    rowId: 'uuid',
  });

  return (
    <DataTable
      table={table}
      enablePagination
      className="bg-gray-00"
      headerConfig={{
        className: 'bg-primary',
      }}
      isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
    >
      <DataTableToolbar table={table} filterFields={filterFields} />
    </DataTable>
  );
}
