/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */

/**
 * * `restaurant` - Restaurant
* `kitchen` - Kitchen
* `store` - Store
* `warehouse` - Warehouse
* `factory` - Factory
* `office` - Office
* `other` - Other
 */
export type SiteTypeEnum = typeof SiteTypeEnum[keyof typeof SiteTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SiteTypeEnum = {
  restaurant: 'restaurant',
  kitchen: 'kitchen',
  store: 'store',
  warehouse: 'warehouse',
  factory: 'factory',
  office: 'office',
  other: 'other',
} as const;
