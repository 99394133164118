import Page from '@app/components/layout/page/page';
import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { useMemo } from 'react';
import { useCCF } from '../../ccf-layout';
import ElectricityEditForm from '../../components/scope-2/electricity-edit-form';
import ElectricityTable from '../../components/scope-2/electricity-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function ElectricityPage() {
  const { showIntercomArticle } = useIntercom();
  const { summary } = useCCF();
  const {
    showSimpleDialog: showElectricityDialog,
    SimpleDialogComponent: ElectricityDialog,
    closeSimpleDialog: closeElectricityDialog,
  } = useSimpleDialog();

  const categoryStatus = useMemo(
    () => summary?.filter((item) => item.slug === 'electricity')[0].status,
    [summary]
  );

  return (
    <Page name="Scope 2 - Electricity">
      {categoryStatus != 'unstarted' ? (
        <ElectricityTable />
      ) : (
        <EmptyCategoryPage
          category="Electricity"
          description="The electricity you purchase and consume on your sites."
          primaryActionProps={{
            onClick: showElectricityDialog,
          }}
          secondaryActionProps={{
            onClick: () => showIntercomArticle('10117592'),
          }}
          image="https://via.placeholder.com/150"
        />
      )}
      {ElectricityDialog({
        header: 'Add electricity reading',
        content: <ElectricityEditForm closeDialog={closeElectricityDialog} />,
      })}
    </Page>
  );
}
