import Page from '@app/components/layout/page/page';
import { useIntercom } from '@app/services/intercom/IntercomContext';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ccfRoutes } from '../../../ccf-routes';
import { useCCF } from '../../ccf-layout';
import FoodTable from '../../components/scope-3/ccf-food-table';
import EmptyCategoryPage from '../ccf-category-intro-page';

export default function CCFFoodAndDrinkPage() {
  const navigate = useNavigate();
  const { showIntercomArticle } = useIntercom();
  const { reportUuid, summary } = useCCF();

  const foodStatus = useMemo(
    () => summary?.filter((item) => item.slug === 'food')[0].status,
    [summary]
  );

  return (
    <Page name="Scope 3 - Food and drink">
      {foodStatus != 'unstarted' ? (
        <FoodTable />
      ) : (
        <EmptyCategoryPage
          category="Food and drink"
          description="The food and drink that your company purchases. This falls under Scope 3, Category 1 of the GHG protocol, Purchased Goods & Services. We expect this to account for ~70% of the total emissions of a food business."
          primaryActionProps={{
            onClick: () =>
              navigate(
                generatePath(ccfRoutes.IMPORT_DOWNLOAD, {
                  report_uuid: reportUuid,
                })
              ),
          }}
          secondaryActionProps={{
            onClick: () => showIntercomArticle('10117592'),
          }}
          image="https://via.placeholder.com/150"
        />
      )}
    </Page>
  );
}
