import { SiteTypeEnum } from '@shared/api/types';

const siteTypeOptions = [
  {
    label: 'Restaurant',
    value: SiteTypeEnum['restaurant'],
  },
  {
    label: 'Kitchen',
    value: SiteTypeEnum['kitchen'],
  },
  {
    label: 'Retail store',
    value: SiteTypeEnum['store'],
  },
  {
    label: 'Warehouse',
    value: SiteTypeEnum['warehouse'],
  },
  {
    label: 'Factory',
    value: SiteTypeEnum['factory'],
  },
  {
    label: 'Office',
    value: SiteTypeEnum['office'],
  },
  {
    label: 'Other',
    value: SiteTypeEnum['other'],
  },
];

export default siteTypeOptions;
